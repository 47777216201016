import obmed from "@/services/obmed";
import { ActionTree, GetterTree, ModuleTree, MutationTree } from "vuex";

import { RootState } from "@/store/types";
import {
  Specialty,
  SpecialtyState,
  SpecialtyLocaleState,
  SpecialtyLocaleMutations,
  SpecialtyLocaleActions,
  SpecialtyLocaleGetters,
} from "@/store/specialty/types";

const { VUE_APP_API_URL } = process.env;

const endpoints = {
  establishmentByID: (_id: number | string) => `estabelecimento/${_id}/especialidades/`,
};

const state: SpecialtyLocaleState = {
  establishment: [],
};

const getters: GetterTree<SpecialtyState, RootState> & SpecialtyLocaleGetters = {};

const mutations: MutationTree<SpecialtyState> & SpecialtyLocaleMutations = {
  setEstablishmentSpecialty(state, specialties) {
    state.establishment = specialties;
  },
};

const actions: ActionTree<SpecialtyState, RootState> & SpecialtyLocaleActions = {
  async getEstablishmentSpecialty({ commit, dispatch }, { ie_tipo }) {
    try {
      const _id = Number(localStorage.getItem("CD_ESTABELECIMENTO"));
      const response = await obmed.get<Specialty[]>({
        entity: endpoints.establishmentByID(_id),
        config: { query: { ie_tipo }, baseURL: VUE_APP_API_URL },
      });
      commit("setEstablishmentSpecialty", response.data);

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
};

const modules: ModuleTree<RootState> = {};

export const SpecialtyStore = { state, getters, modules, mutations, actions };
