import { RouteRecordRaw } from "vue-router";

import LAuth from "@/layouts/Auth.vue";
import LDashboard from "@/layouts/Dashboard.vue";

const unauthenticatedRoutes: RouteRecordRaw[] = [
  {
    path: "/auth",
    name: "auth",
    component: LAuth,
    redirect: { name: "login" },
    meta: { requiresNotLogged: true },
    children: [
      {
        path: "/login",
        name: "login",
        component: () => import("@/views/Login.vue"),
      },
      {
        path: "/esqueci-minha-senha",
        name: "forgot-password",
        component: () => import("@/views/Forgot.vue"),
      },
      {
        path: "/forgotmypassword",
        redirect: (to) => ({ name: "recover-password", query: to.query }),
      },
      {
        path: "/redefinir-senha",
        name: "recover-password",
        component: () => import("@/views/Recover.vue"),
        beforeEnter: (to) => (!to.query.token ? { name: "root" } : true),
      },
    ],
  },
];

const authenticatedRoutes: RouteRecordRaw[] = [
  {
    path: "/",
    name: "root",
    component: LDashboard,
    redirect: { name: "guide-list" },
    meta: { requiresAuth: true },
    children: [
      {
        path: "/guias-eletronicas",
        name: "guide-list",
        alias: "/home",
        component: () => import("@/views/guide/List.vue"),
        children: [
          {
            path: "/guias-eletronicas/validar",
            name: "guide-validate",
            component: () => import("@/views/guide/Validate.vue"),
          },
          {
            path: "/guias-eletronicas/filtrar",
            name: "guide-filter",
            component: () => import("@/views/guide/Filter.vue"),
          },
          {
            path: "/guias-eletronicas/validar/confirmar",
            name: "guide-confirm-validation",
            component: () => import("@/views/guide/ConfirmValidation.vue"),
            beforeEnter: (to) => (!to.query.guide || !to.query.hash ? { name: "guide-validate" } : true),
          },
          {
            path: "/guias-eletronicas/:_id/contanto/email",
            name: "guide-contact-email",
            component: () => import("@/views/guide/Contact.vue"),
          },
          {
            path: "/guias-eletronicas/:_id/contanto/whatsapp",
            name: "guide-contact-whatsapp",
            component: () => import("@/views/guide/Contact.vue"),
          },
          {
            path: "/relatorios",
            name: "report",
            redirect: { name: "report-revenues" },
            component: () => import("@/views/report/Report.vue"),
            children: [
              {
                path: "/relatorios/faturamento",
                name: "report-revenues",
                component: () => import("@/views/report/Revenues.vue"),
              },
            ],
          },
        ],
      },
      {
        path: "/especialistas",
        name: "specialist-list",
        component: () => import("@/views/specialist/List.vue"),
        children: [
          {
            path: "/especialistas/adicionar",
            name: "specialist-add",
            component: () => import("@/views/specialist/Form.vue"),
          },
          {
            path: "/especialistas/filtrar",
            name: "specialist-filter",
            component: () => import("@/views/specialist/Filter.vue"),
          },
          {
            path: "/especialistas/:_id/editar",
            name: "specialist-edit",
            component: () => import("@/views/specialist/Form.vue"),
          },
          {
            path: "/especialistas/:_id/remover",
            name: "specialist-delete",
            component: () => import("@/views/specialist/ConfirmDeletion.vue"),
          },
        ],
      },
      {
        path: "/agendas",
        name: "agenda-list",
        component: () => import("@/views/agenda/List.vue"),
        redirect: { name: "agenda-consultation" },
        children: [
          {
            path: "/agendas/consultas",
            name: "agenda-consultation",
            component: () => import("@/views/agenda/Consultation.vue"),
            children: [
              {
                path: "/agendas/consultas/filtrar",
                name: "agenda-consultation-filter",
                component: () => import("@/views/agenda/Filter.vue"),
              },
              {
                path: "/agendas/consultas/adicionar",
                name: "agenda-consultation-add",
                component: () => import("@/views/agenda/Form.vue"),
              },
              {
                path: "/agendas/consultas/:_id/editar",
                name: "agenda-consultation-edit",
                component: () => import("@/views/agenda/Form.vue"),
              },
            ],
          },
          {
            path: "/agendas/exames",
            name: "agenda-exam",
            component: () => import("@/views/agenda/Exam.vue"),
            children: [
              {
                path: "/agendas/exames/filtrar",
                name: "agenda-exam-filter",
                component: () => import("@/views/agenda/Filter.vue"),
              },
              {
                path: "/agendas/exames/adicionar",
                name: "agenda-exam-add",
                component: () => import("@/views/agenda/Form.vue"),
              },
              {
                path: "/agendas/exames/:_id/editar",
                name: "agenda-exam-edit",
                component: () => import("@/views/agenda/Form.vue"),
              },
            ],
          },
          {
            path: "/agendas/exames/laboratoriais",
            name: "agenda-laboratory",
            component: () => import("@/views/agenda/Laboratory.vue"),
            children: [
              {
                path: "/agendas/exames/laboratoriais/filtrar",
                name: "agenda-laboratory-filter",
                component: () => import("@/views/agenda/Filter.vue"),
              },
              {
                path: "/agendas/exames/laboratoriais/adicionar",
                name: "agenda-laboratory-add",
                component: () => import("@/views/agenda/Form.vue"),
              },
              {
                path: "/agendas/exames/laboratoriais/:_id/editar",
                name: "agenda-laboratory-edit",
                component: () => import("@/views/agenda/Form.vue"),
              },
            ],
          },
          {
            path: "/agendas/procedimentos",
            name: "agenda-procedure",
            component: () => import("@/views/agenda/Procedure.vue"),
            children: [
              {
                path: "/agendas/procedimentos/filtrar",
                name: "agenda-procedure-filter",
                component: () => import("@/views/agenda/Filter.vue"),
              },
              {
                path: "/agendas/procedimentos/adicionar",
                name: "agenda-procedure-add",
                component: () => import("@/views/agenda/Form.vue"),
              },
              {
                path: "/agendas/procedimentos/:_id/editar",
                name: "agenda-procedure-edit",
                component: () => import("@/views/agenda/Form.vue"),
              },
            ],
          },
          {
            path: "/agendas/pacotes-servicos",
            name: "agenda-service",
            component: () => import("@/views/agenda/Service.vue"),
            children: [
              {
                path: "/agendas/pacotes-servicos/filtrar",
                name: "agenda-service-filter",
                component: () => import("@/views/agenda/Filter.vue"),
              },
              {
                path: "/agendas/pacotes-servicos/adicionar",
                name: "agenda-service-add",
                component: () => import("@/views/agenda/Form.vue"),
              },
              {
                path: "/agendas/pacotes-servicos/:_id/editar",
                name: "agenda-service-edit",
                component: () => import("@/views/agenda/Form.vue"),
              },
            ],
          },
          {
            path: "/agendas/:_id/atendimentos",
            name: "agenda-attendance",
            component: () => import("@/views/agenda/Attendance.vue"),
            children: [
              {
                path: "/agendas/:_id/atendimentos/adicionar",
                name: "agenda-attendance-add",
                component: () => import("@/views/agenda/AttendanceForm.vue"),
              },
              {
                path: "/agendas/:_id/atendimentos/editar",
                name: "agenda-attendance-edit",
                component: () => import("@/views/agenda/AttendanceForm.vue"),
              },
            ],
          },
          {
            path: "/agendas/:_id/bloqueios",
            name: "agenda-lock",
            component: () => import("@/views/agenda/Lock.vue"),
            children: [
              {
                path: "/agendas/:_id/bloqueios/adicionar",
                name: "agenda-lock-add",
                component: () => import("@/views/agenda/LockForm.vue"),
              },
              {
                path: "/agendas/:_id/bloqueios/editar",
                name: "agenda-lock-edit",
                component: () => import("@/views/agenda/LockForm.vue"),
              },
            ],
          },
          {
            path: "/agendas/:_id/remover",
            name: "agenda-delete",
            component: () => import("@/views/agenda/ConfirmDeletion.vue"),
          },
        ],
      },
      {
        path: "/analytics",
        name: "analytics",
        component: () => import("@/views/analytic/Analytic.vue"),
      },
      {
        path: "/caixa/:type",
        name: "cashier",
        component: () => import("@/views/cashier/Cashier.vue"),
      },
      {
        path: "/sair",
        name: "logout",
        component: () => import("@/views/Logout.vue"),
      },
    ],
  },
];

export default [
  ...authenticatedRoutes,
  ...unauthenticatedRoutes,
  {
    path: "/:pathMatch(.*)*",
    name: "page-not-found",
    component: () => import("@/layouts/NotFound.vue"),
  },
];
