import obmed from "@/services/obmed";
import { ActionTree, GetterTree, ModuleTree, MutationTree } from "vuex";

import { RootState } from "@/store/types";
import {
  Agenda,
  AgendaState,
  AgendaLocaleState,
  AgendaLocaleMutations,
  AgendaLocaleActions,
  AgendaLocaleGetters,
  AgendaForm,
  AgendaAttendance,
  AgendaAttendanceForm,
  AgendaLock,
  AgendaLockForm,
} from "@/store/agenda/types";
import { PaginationParams, PaginationResponse } from "../types";

const { VUE_APP_API_URL } = process.env;

const endpoints = {
  root: "agenda/",
  byID: (_id: number | string) => `agenda/${_id}/`,
  attendance: (_id: number | string) => `agenda/${_id}/dias-atendimento/`,
  attendanceByID: (_id: number | string, attendanceID: number | string) =>
    `agenda/${_id}/dias-atendimento/${attendanceID}/`,
  lock: "agenda/bloqueio/",
  lockByID: (_id: number | string) => `agenda/bloqueio/${_id}/`,
  lockByAgendaID: (_id: number | string) => `agenda/bloqueio/?cd_agenda=${_id}`,
};

const state: AgendaLocaleState = {
  current: null,
  list: [],
  searchList: [],
  pagination: { page: 1, total: 1, items: 0, perPage: 25 },
  attendanceDays: [],
  lockDays: [],
};

const getters: GetterTree<AgendaState, RootState> & AgendaLocaleGetters = {};

const mutations: MutationTree<AgendaState> & AgendaLocaleMutations = {
  setAgenda(state, agenda) {
    state.current = agenda;
  },
  setAgendas(state, agendas) {
    state.list = agendas;
  },
  setSearchAgendas(state, agendas) {
    state.searchList = agendas;
  },
  setAgendaPagination(state, pagination) {
    state.pagination = pagination;
  },
  setAgendaPaginationPage(state, { page }) {
    state.pagination.page = page;
  },
  setAgendaAttendanceDays(state, agendas) {
    state.attendanceDays = agendas;
  },
  setAgendaLockDays(state, agendas) {
    state.lockDays = agendas;
  },
};

const actions: ActionTree<AgendaState, RootState> & AgendaLocaleActions = {
  async getAgenda({ commit, dispatch }, { _id }) {
    try {
      const response = await obmed.get<Agenda>({
        entity: endpoints.byID(_id),
        config: { baseURL: VUE_APP_API_URL },
      });
      commit("setAgenda", response.data || []);

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async getAgendas({ state, commit, dispatch }, { ie_tipo_agenda }) {
    const pagination: PaginationParams = {
      page: state.pagination.page,
      per_page: state.pagination.perPage,
      paginacao: true,
    };
    try {
      const response = await obmed.get<PaginationResponse<Agenda>>({
        entity: endpoints.root,
        config: { query: { ...pagination, ie_tipo_agenda }, baseURL: VUE_APP_API_URL },
      });

      commit("setAgendas", response.data?.results || []);
      commit("setAgendaPagination", { ...state.pagination, items: response.data.count });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async createAgenda({ commit, dispatch }, { form }) {
    try {
      const response = await obmed.post<Agenda, AgendaForm>({
        entity: endpoints.root,
        data: form,
        config: { baseURL: VUE_APP_API_URL },
      });

      if (response.status === 200)
        commit("addToast", {
          summary: "Cadastro realizado com sucesso!",
          severity: "success",
        });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async updateAgenda({ commit, dispatch }, { _id, form }) {
    try {
      const response = await obmed.patch<Agenda, AgendaForm>({
        entity: endpoints.byID(_id),
        data: form,
        config: { baseURL: VUE_APP_API_URL },
      });

      if (response.status === 200)
        commit("addToast", {
          summary: "Agenda atualizada com sucesso!",
          severity: "success",
        });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async deleteAgenda({ commit, dispatch }, { _id }) {
    try {
      const response = await obmed.delete({
        entity: endpoints.byID(_id),
        config: { baseURL: VUE_APP_API_URL },
      });

      if (response.status === 200)
        commit("addToast", {
          summary: "Agenda excluída com sucesso!",
          severity: "success",
        });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async updateAgendaAppointment({ dispatch }, { _id, ie_hora_marcada }) {
    try {
      const response = await obmed.patch<Agenda, any>({
        entity: endpoints.byID(_id),
        data: { ie_hora_marcada },
        config: { baseURL: VUE_APP_API_URL },
      });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async updateAgendaRestriction({ dispatch }, { _id, ie_agenda_restrita }) {
    try {
      const response = await obmed.patch<Agenda, any>({
        entity: endpoints.byID(_id),
        data: { ie_agenda_restrita },
        config: { baseURL: VUE_APP_API_URL },
      });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async getAgendaAttendanceDays({ commit, dispatch }, { _id }) {
    try {
      const response = await obmed.get<AgendaAttendance[]>({
        entity: endpoints.attendance(_id),
        config: { baseURL: VUE_APP_API_URL },
      });
      commit("setAgendaAttendanceDays", response.data || []);

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async addAgendaAttendanceDay({ commit, dispatch }, { _id, form }) {
    try {
      const response = await obmed.post<AgendaAttendance, AgendaAttendanceForm>({
        entity: endpoints.attendance(_id),
        data: form,
        config: { baseURL: VUE_APP_API_URL },
      });

      if (response.status === 200)
        commit("addToast", {
          summary: "Atendimento adicionado com sucesso!",
          severity: "success",
        });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async editAgendaAttendanceDay({ commit, dispatch }, { _id, form }) {
    try {
      const response = await obmed.patch<AgendaAttendance, AgendaAttendanceForm>({
        entity: endpoints.attendanceByID(form.cd_agenda, _id),
        data: form,
        config: { baseURL: VUE_APP_API_URL },
      });

      if (response.status === 200)
        commit("addToast", {
          summary: "Atendimento atualizado com sucesso!",
          severity: "success",
        });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async deleteAgendaAttendanceDay({ commit, dispatch }, { _id, attendanceID }) {
    try {
      const response = await obmed.delete({
        entity: endpoints.attendanceByID(_id, attendanceID),
        config: { baseURL: VUE_APP_API_URL },
      });

      if (response.status === 200)
        commit("addToast", {
          summary: "Atendimento removido com sucesso!",
          severity: "success",
        });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async getAgendaLockDays({ commit, dispatch }, { _id }) {
    try {
      const response = await obmed.get<AgendaLock[]>({
        entity: endpoints.lockByAgendaID(_id),
        config: { baseURL: VUE_APP_API_URL },
      });
      commit("setAgendaLockDays", response.data || []);

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async addAgendaLockDay({ commit, dispatch }, { form }) {
    try {
      const response = await obmed.post<AgendaLock, AgendaLockForm>({
        entity: endpoints.lock,
        data: form,
        config: { baseURL: VUE_APP_API_URL },
      });

      if (response.status === 201)
        commit("addToast", {
          summary: "Bloqueio adicionado com sucesso!",
          severity: "success",
        });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async editAgendaLockDay({ commit, dispatch }, { _id, form }) {
    try {
      const response = await obmed.patch<AgendaLock, AgendaLockForm>({
        entity: endpoints.lockByID(_id),
        data: form,
        config: { baseURL: VUE_APP_API_URL },
      });

      if (response.status === 200)
        commit("addToast", {
          summary: "Bloqueio atualizado com sucesso!",
          severity: "success",
        });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async deleteAgendaLockDay({ commit, dispatch }, { _id }) {
    try {
      const response = await obmed.delete({
        entity: endpoints.lockByID(_id),
        config: { baseURL: VUE_APP_API_URL },
      });

      if (response.status === 204)
        commit("addToast", {
          summary: "Bloqueio removido com sucesso!",
          severity: "success",
        });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async searchAgendas({ state, commit, dispatch }, { search, isPrimary }) {
    try {
      const pagination: PaginationParams = { page: 1, per_page: 25, paginacao: true };
      const response = await obmed.get<PaginationResponse<Agenda>>({
        entity: endpoints.root,
        config: { query: { ...search, ...pagination }, baseURL: VUE_APP_API_URL },
      });

      commit("setSearchAgendas", response.data?.results || []);

      if (isPrimary) {
        commit("setAgendas", response.data?.results || []);
        commit("setAgendaPagination", { ...state.pagination, items: response.data.count });
      }

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
};

const modules: ModuleTree<RootState> = {};

export const AgendaStore = { state, getters, modules, mutations, actions };
