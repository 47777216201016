import obmed from "@/services/obmed";
import { ActionTree, GetterTree, ModuleTree, MutationTree } from "vuex";

import { RootState } from "@/store/types";
import {
  Procedure,
  ProcedureState,
  ProcedureLocaleState,
  ProcedureLocaleMutations,
  ProcedureLocaleActions,
  ProcedureLocaleGetters,
} from "@/store/procedure/types";

const { VUE_APP_API_URL } = process.env;

const endpoints = {
  establishmentByID: (_id: number | string) => `procedimentos/estabelecimento/${_id}/`,
};

const state: ProcedureLocaleState = {
  establishment: [],
};

const getters: GetterTree<ProcedureState, RootState> & ProcedureLocaleGetters = {};

const mutations: MutationTree<ProcedureState> & ProcedureLocaleMutations = {
  setEstablishmentProcedures(state, procedures) {
    state.establishment = procedures;
  },
};

const actions: ActionTree<ProcedureState, RootState> & ProcedureLocaleActions = {
  async getEstablishmentProcedure({ commit, dispatch }, { ie_grupo, show }) {
    try {
      const _id = Number(localStorage.getItem("CD_ESTABELECIMENTO"));
      const response = await obmed.get<Procedure[]>({
        entity: endpoints.establishmentByID(_id),
        config: { query: { ie_grupo, show }, baseURL: VUE_APP_API_URL },
      });
      commit("setEstablishmentProcedures", response.data);

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
};

const modules: ModuleTree<RootState> = {};

export const ProcedureStore = { state, getters, modules, mutations, actions };
