import { createApp } from "vue";
import { createHead } from "@vueuse/head";
import PrimeVue from "primevue/config";

import { router } from "@/router";
import { store } from "@/store";

import "primevue/resources/primevue.min.css";
import "primevue/resources/themes/saga-green/theme.css";
import "primeicons/primeicons.css";

import App from "@/App.vue";
import { localeConfig } from "@/plugins/primevue";

import "@/registerServiceWorker";

import "@/libs/styles/main.scss";
import "@/libs/styles/obmed.scss";
import "@/assets/config.scss";
import "bulma-calendar/dist/css/bulma-calendar.min.css";

createApp(App).use(store).use(router).use(PrimeVue, { locale: localeConfig }).use(createHead()).mount("#app");
