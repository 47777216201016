import obmed from "@/services/obmed";
import { ActionTree, GetterTree, ModuleTree, MutationTree } from "vuex";

import { RootState } from "@/store/types";
import {
  Patient,
  PatientState,
  PatientLocaleState,
  PatientLocaleMutations,
  PatientLocaleActions,
  PatientLocaleGetters,
} from "@/store/patient/types";
import { PaginationParams, PaginationResponse } from "../types";

const { VUE_APP_API_URL } = process.env;

const endpoints = {
  root: "paciente/",
};

const state: PatientLocaleState = {
  list: [],
  searchList: [],
  pagination: { page: 1, total: 1, items: 0, perPage: 25 },
};

const getters: GetterTree<PatientState, RootState> & PatientLocaleGetters = {};

const mutations: MutationTree<PatientState> & PatientLocaleMutations = {
  setPatients(state, patients) {
    state.list = patients;
  },
  setSearchPatients(state, patients) {
    state.searchList = patients;
  },
  setPatientPagination(state, pagination) {
    state.pagination = pagination;
  },
  setPatientPaginationPage(state, { page }) {
    state.pagination.page = page;
  },
};

const actions: ActionTree<PatientState, RootState> & PatientLocaleActions = {
  async searchPatients({ state, commit, dispatch }, { search, isPrimary }) {
    try {
      const pagination: PaginationParams = { page: 1, per_page: 25, paginacao: true };
      const response = await obmed.get<PaginationResponse<Patient>>({
        entity: endpoints.root,
        config: { query: { ...search, ...pagination }, baseURL: VUE_APP_API_URL },
      });

      commit("setSearchPatients", response.data?.results || []);

      if (isPrimary) {
        commit("setPatients", response.data?.results || []);
        commit("setPatientPagination", { ...state.pagination, items: response.data.count });
      }

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
};

const modules: ModuleTree<RootState> = {};

export const PatientStore = { state, getters, modules, mutations, actions };
