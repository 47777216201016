import { ActionTree, GetterTree, ModuleTree, MutationTree } from "vuex";
import { RootState } from "@/store/types";

import {
  AnalyticLocaleActions,
  AnalyticLocaleGetters,
  AnalyticLocaleMutations,
  AnalyticLocaleState,
  AnalyticState,
  Report,
} from "./type";
import obmed from "@/services/obmed";

const { VUE_APP_API_URL } = process.env;

const endpoints = {
  report: "quantidade-de-procedimentos/",
};

const state: AnalyticLocaleState = {
  reports: [],
};

const getters: GetterTree<AnalyticState, RootState> & AnalyticLocaleGetters = {};

const mutations: MutationTree<AnalyticState> & AnalyticLocaleMutations = {
  setReport(state, reports) {
    state.reports = reports;
  },
};

const actions: ActionTree<AnalyticState, RootState> & AnalyticLocaleActions = {
  async getReportAnalytic({ dispatch, commit }, { dt_inicial, dt_final }) {
    try {
      const response = await obmed.get<Report[]>({
        entity: endpoints.report,
        config: {
          query: { dt_inicial, dt_final },
          baseURL: VUE_APP_API_URL,
        },
      });
      commit("setReport", response.data || []);

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
};
const modules: ModuleTree<RootState> = {};

export const AnalyticStore = { state, getters, modules, mutations, actions };
