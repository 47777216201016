
import { computed, defineComponent, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

import CDropdown, { DropdownItem } from "@/components/Dropdown.vue";

import menus from "@/router/menus";
import { UserEstablishment } from "@/store/user/types";

const CAppbar = defineComponent({
  name: "CAppbar",
  components: { CDropdown },
  setup() {
    const store = useStore();
    const router = useRouter();

    const user = computed(() => store.getters.userData);
    const currentEstablishment = computed(() => store.state.user.establishment);
    const handledMenus = computed(() => {
      const permissions = user.value?.permissoes || [];
      if (!user.value) return [];

      return menus
        .map((menu: any) => {
          if (menu.items) {
            const items = menu.items.filter((submenu: any) => permissions.includes(String(submenu.permission)));
            menu.items = (items.length ? items : undefined) as any;

            return menu;
          }

          return menu;
        })
        .filter((menu) => menu.items || permissions.includes(String(menu.permission)));
    });
    const userName = computed(() => {
      const name = String(`${user.value?.first_name} ${user.value?.last_name}`.trim().split(" ").slice(0, 2).join(" "));

      if (name === "undefined") return undefined;
      if (name?.length > 16) return name.split(" ")[0];

      return name;
    });

    const accountDropdownItems = ref<DropdownItem[]>([]);

    function setUserEstablishment(establishment: UserEstablishment) {
      store.commit("setUserEstablishment", establishment);
      router.go(0);
    }

    function handleAccountDropdown() {
      const items: DropdownItem[] = [{ label: "Encerrar sessão", icon: "fas fa-sign-out-alt", to: { name: "logout" } }];

      if (user.value) {
        items.unshift({ isDivider: true });

        user.value.estabelecimentos.forEach((item) => {
          items.unshift({
            label: item.nm_fantasia,
            icon: "far fa-building",
            isActive: currentEstablishment.value?.pk === item.pk,
            command: () => setUserEstablishment(item),
          });
        });
      }

      accountDropdownItems.value = items;
    }

    handleAccountDropdown();
    watch([user, currentEstablishment], handleAccountDropdown);

    return { userName, currentEstablishment, accountDropdownItems, handledMenus };
  },
});

export default CAppbar;
