import { ActionTree, GetterTree, ModuleTree, MutationTree } from "vuex";
import { RootState } from "@/store/types";

import {
  ReportLocaleActions,
  ReportLocaleGetters,
  ReportLocaleMutations,
  ReportLocaleState,
  ReportRevenues,
  ReportState,
} from "@/store/report/type";
import obmed from "@/services/obmed";

const { VUE_APP_API_URL } = process.env;

const endpoints = {
  revenues: "faturamento-clinica/",
};

const state: ReportLocaleState = {};

const getters: GetterTree<ReportState, RootState> & ReportLocaleGetters = {};

const mutations: MutationTree<ReportState> & ReportLocaleMutations = {};

const actions: ActionTree<ReportState, RootState> & ReportLocaleActions = {
  async getReportRevenues({ dispatch }, { dt_inicial, dt_final }) {
    try {
      const response = await obmed.get<ReportRevenues>({
        entity: endpoints.revenues,
        config: {
          query: { dt_inicial, dt_final },
          baseURL: VUE_APP_API_URL,
        },
      });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
};
const modules: ModuleTree<RootState> = {};

export const ReportStore = { state, getters, modules, mutations, actions };
