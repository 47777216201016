import { ActionTree, GetterTree, ModuleTree, MutationTree } from "vuex";
import obmed from "@/services/obmed";

import { RootState } from "@/store/types";
import {
  Guide,
  GuideState,
  GuideLocaleState,
  GuideLocaleMutations,
  GuideLocaleActions,
  GuideLocaleGetters,
  GuideValidation,
} from "@/store/guide/types";
import { PaginationParams, PaginationResponse } from "../types";

const { VUE_APP_API_URL } = process.env;

const endpoints = {
  root: "atendimento/",
  pdf: "guia-eletronica-em-pdf/",
  pdfByID: (_id: number | string) => `guia-eletronica-em-pdf/${_id}/`,
  validate: "validar-guia/",
  confirmValidation: "executar-guias/",
};

const state: GuideLocaleState = {
  list: [],
  searchList: [],
  validationList: [],
  pagination: { page: 1, total: 1, items: 0, perPage: 25 },
};

const getters: GetterTree<GuideState, RootState> & GuideLocaleGetters = {};

const mutations: MutationTree<GuideState> & GuideLocaleMutations = {
  setGuides(state, guides) {
    state.list = guides;
  },
  setSearchGuides(state, guides) {
    state.searchList = guides;
  },
  setGuidesValidation(state, guides) {
    state.validationList = guides;
  },
  setGuidePagination(state, pagination) {
    state.pagination = pagination;
  },
  setGuidePaginationPage(state, { page }) {
    state.pagination.page = page;
  },
};

const actions: ActionTree<GuideState, RootState> & GuideLocaleActions = {
  async getGuides({ state, commit, dispatch }) {
    const pagination: PaginationParams = {
      page: state.pagination.page,
      per_page: state.pagination.perPage,
      paginacao: true,
    };
    try {
      const response = await obmed.get<PaginationResponse<Guide>>({
        entity: endpoints.root,
        config: { query: { ...pagination }, baseURL: VUE_APP_API_URL },
      });

      commit("setGuides", response.data?.results || []);
      commit("setGuidePagination", { ...state.pagination, items: response.data.count });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async getPDFGuide({ dispatch }, { _id, id_guia_eletronica }) {
    try {
      const response = await obmed.get<any>({
        entity: _id ? endpoints.pdfByID(_id) : endpoints.pdf,
        config: { query: { id_guia_eletronica }, baseURL: VUE_APP_API_URL },
      });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async validateGuide({ commit, dispatch }, { form }) {
    try {
      const response = await obmed.post<GuideValidation[]>({
        entity: endpoints.validate,
        data: form,
        config: { baseURL: VUE_APP_API_URL },
      });
      commit("setGuidesValidation", response.data);

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async confirmValidationGuide({ commit, dispatch }, { form }) {
    try {
      const response = await obmed.post<[]>({
        entity: endpoints.confirmValidation,
        data: form,
        config: { baseURL: VUE_APP_API_URL },
      });

      if (response?.status === 200)
        commit("addToast", {
          summary: "Guia(s) validada(s) com sucesso!",
          severity: "success",
        });

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
  async searchGuides({ state, commit, dispatch }, { search, isPrimary }) {
    try {
      const pagination: PaginationParams = { page: 1, per_page: 25, paginacao: true };
      const response = await obmed.get<PaginationResponse<Guide>>({
        entity: endpoints.root,
        config: { query: { ...search, ...pagination }, baseURL: VUE_APP_API_URL },
      });

      commit("setSearchGuides", response.data?.results || []);

      if (isPrimary) {
        commit("setGuides", response.data?.results || []);
        commit("setGuidePagination", { ...state.pagination, items: response.data.count });
      }

      return response;
    } catch (error) {
      dispatch("handleError", error);
    }
  },
};

const modules: ModuleTree<RootState> = {};

export const GuideStore = { state, getters, modules, mutations, actions };
