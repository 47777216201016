export default [
  {
    label: "Agenda",
    to: { name: "agenda-list" },
    icon: "fas fa-calendar-alt",
    permission: "agenda:::get",
  },
  {
    label: "Atendimento",
    to: { name: "guide-list" },
    icon: "fas fa-hand-holding-medical",
    permission: "atendimento:::get",
  },
  {
    label: "Especialistas",
    to: { name: "specialist-list" },
    icon: "fas fa-user-md",
    permission: "especialista:::get",
  },
  {
    label: "Analytics",
    to: { name: "analytics" },
    icon: "fas fa-chart-bar",
    permission: "analytics:::get",
  },
  {
    label: "Relatórios",
    to: { name: "report" },
    icon: "fas fa-print",
    permission: "relatorio:::get",
  },
];
